<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "trainer-inclass-assessment-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-inclass-assessment-create',
                          'trainer-inclass-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'trainer-inclass-assessment-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-inclass-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-inclass-assessment-create',
                          'trainer-inclass-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                          'trainer-inclass-assessment-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-inclass-assessment-create'])
                      "
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-inclass-assessment-create',
                          'trainer-inclass-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'trainer-inclass-assessment-update'
                        "
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.inclass.level.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.inclass.level.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-inclass-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-inclass-assessment-create',
                          'trainer-inclass-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName ==
                          'trainer-inclass-assessment-update'
                        "
                        :options="selectedWeight ? employeeLists : []"
                        :selectable="(option) => isSelectable(option)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-inclass-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedEmployee"
                        label="employee_name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="inclass.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="inclass.join_date"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-4 form-label"
                    >
                    Choose Assessment Criteria
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'trainer-inclass-assessment-create',
                          'trainer-inclass-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedCriteria($event)"
                        label="name"
                        :options="weights"
                        :reduce="(weight) => weight.id"
                        :clearable="false"
                        v-model="selectedWeight"
                        :selectable="(options) => isSelectableCriteria(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedWeight.$error ||
                            errorFor('Criteria Weight'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('Criteria Weight')"
                        :vuelidateErrors="{
                          errors: v$.selectedWeight.$errors,
                          value: 'Criteria Weight',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['trainer-inclass-assessment-list'])
                      "
                    >
                      <v-select
                        label="evaluating_criteria_name"
                        :clearable="false"
                        v-model="selectedWeight"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <template
                v-if="
                  hasPermissions([
                    'trainer-inclass-assessment-create',
                    'trainer-inclass-assessment-edit',
                  ])
                "
                >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :options="scoreOptions(criteria)"
                        :clearable="false"
                        @option:selected="updateTotalScore"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template
                v-else-if="hasPermissions(['trainer-inclass-assessment-edit'])"
              >
                <div
                  class="row g-3"
                  v-for="(criteria, index) in criterias"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="criteria.selectedScore"
                        :clearable="false"
                        disabled
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Remark"
                        v-model="criteria.remark"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </template>

              <div class="row g-3 pt-2">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="totalScore"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-inclass-assessment-create',
                    'trainer-inclass-assessment-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-inclass-assessment' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="totalScore == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="Your total score is still 0."
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                    >
                      <span>Save</span>
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'trainer-inclass-assessment-update'
                          ? updateTrainerInclassAssessment()
                          : createTrainerInclassAssessment()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { levels } from '../../../const';

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batch: [],
      levels: levels,
      employeeLists: [],
      criterias: [],
      totalScore: 0,
      existedEmployees: [],
      inclass: {
        type: "In-Class",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
        level: "",
      },
      weights: [],
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      selectedWeight: null,
      selectedLevel: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      selectedLevel: { required },
      selectedWeight: { required },
      inclass: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
        level: {
          required: requiredIf(() => {
            return this.inclass.type != "Orientation";
          }),
        },
      },
    };
  },
  methods: {
    isSelectableCriteria(options) {
      if (!this.selectedWeight || !options) return true;
      if (Array.isArray(this.selectedWeight)) {
        return this.selectedWeight[0].id != options.id;
      } else {
        return this.selectedWeight.id != options.id;
      }
    },
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if (!this.selectedLevel || !options) return true;
      return this.selectedLevel.id != options.id;
    },
    isSelectable(options) {
      if (this.selectedEmployee) {
        const empExist = this.existedEmployees.some(
          (item) =>
            item.employee_id == this.selectedEmployee.employee_id &&
            item.level == this.selectedLevel.name &&
            item.assessment_criteria_id == this.selectedWeight.id
        );
        // If the batch doesn't exist, add it to existedEmployees
        if (!empExist) {
          this.existedEmployees = this.existedEmployees.filter(
            (item) => !item.is_selected
          );
          this.existedEmployees.push({
            employee_id: this.selectedEmployee.employee_id,
            level: this.selectedLevel.name,
            assessment_criteria_id: this.selectedWeight.id, 
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in existedEmployees
      return !this.existedEmployees.find(
        (item) =>
          item.employee_id == options.employee_id &&
          item.level == this.selectedLevel.name &&
          item.assessment_criteria_id == this.selectedWeight.id
      );
    },
    // async getAllLevels() {
    //   await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
    //     this.levels = response.data.data;
    //   });
    // },
    async getAllCriterias() {
        let program_id, level;
        program_id = this.inclass.program_id;
        level = this.selectedLevel;

      if (!program_id || !level || !this.selectedBatch) return;

      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment-getCriteria?program_id=${program_id}&type=${this.inclass.type}&level=${level.name || level}`
        )
        .then((response) => {
          this.weights = [];
          let res = response.data.data;
          for(let val of res){
            if(val.trainer_criterias.length) {
              this.weights.push(val);
            }
            
            if(val.id === this.selectedWeight) {
              this.selectedWeight = val
            }
          }

        //   this.toast.error("Trainer Criteria not have");
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong,");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    getSelectedCriteria(val) {
      this.criterias = [];
      this.totalScore = 0;
      if(val.trainer_criterias) {
        this.criterias = val.trainer_criterias;
        this.selectedWeight = val;
        this.totalScore = val.trainer_criterias.reduce(
          (total, value) => total + value.lowest_score,
          0
        );
        this.criterias.forEach((criteria) => {
          criteria.selectedScore = criteria.lowest_score;
          criteria.remark = null;
        });
      }
      
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.criterias = [];
        this.totalScore = 0;
        this.clearData();
        this.inclass.program_id = this.selectedProgram.id;
        this.inclass.program_name = this.selectedProgram.name;
        this.inclass.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.inclass.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.inclass.program_batch_id = this.selectedBatch.id;
        this.inclass.program_batch_no = this.selectedBatch.batch_no;
        this.inclass.available = this.selectedBatch.available;
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.inclass.employee_id = this.selectedEmployee.employee_id;
        this.inclass.employee_name = this.selectedEmployee.employee_name;
        this.inclass.join_date = this.selectedBatch.join_date;
      }
    },
    getSelectedLevel() {
      if(!this.selectedBatch) return;
      if (this.selectedLevel) {
        this.inclass.employee_id = "";
        this.inclass.employee_name = "";
        this.inclass.join_date = "";
        this.selectedWeight = null;
        this.criterias = [];
        this.totalScore = 0;
        this.selectedEmployee = null;
        this.inclass.level = this.selectedLevel.name;
        this.getEmployeeByBatch(this.inclass.program_batch_id);
        this.getAllCriterias();
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.selectedWeight = null;
      this.selectedLevel = null;
      this.inclass.employee_id = "";
      this.inclass.employee_name = "";
      this.inclass.join_date = "";
      this.inclass.level = "";
      this.weights = [];
      this.selectedWeight = "";
      this.criterias = [];

    },

    updateTotalScore() {
      let totalScore = 0;
      for (let i = 0; i < this.criterias.length; i++) {
        totalScore += this.criterias[i].selectedScore;
      }
      this.totalScore = totalScore;
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getBatchByProgram(program_id) {
    
    this.loading = true;
    this.$Progress.start();
    await axios
      .get(
        `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
      )
      .then((response) => {
      
        this.batch = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      })
      .catch(() => {
        this.toast.error("Not Found Batch!");
      });
  },
  async getEmployeeByBatch(batch_id) {
    this.loading = true;
    this.$Progress.start();
    await axios
      .get(
        `${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`
      )
      .then((response) => {
      
        const isCreateRoute = this.currentRouteName === "trainer-inclass-assessment-create"

        this.employeeLists = response.data.data.employee_ids.map(
          (item) => {
            if(isCreateRoute && item.employee.active) {
            return {
              employee_name: item.employee.name,
              employee_id: item.employee.emp_id,
            };
          }
          return null
          }
        )?.filter(item => item !== null);

        this.loading = false;
        this.$Progress.finish();
      })
      .catch(() => {
        this.toast.error("Not Found Employee!");
      });
  },

    async createTrainerInclassAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      let criteria = this.criterias.map((criteria) => {
        return {
          trainer_criteria_id: criteria.id,
          score: criteria.selectedScore,
          remark: criteria.remark,
        };
      });

      let toSendData = {
            program_id: this.inclass.program_id,
            batch_id: this.inclass.program_batch_id,
            employee_id: this.inclass.employee_id,
            assessor_employee_id: this.$store.state.auth.user.employee_id,
            type: "In-Class",
            level : this.inclass.level,
            assessment_point : this.selectedWeight.point,
            assessment_criteria_id: this.selectedWeight.id,
            trainer_criterias : criteria
      };

      await axios
        .post(`${this.baseUrl}admin/v2/trainer-assessment`, toSendData)
        .then(() => {
          this.toast.success("Successfully Inclass Assessment Created.");
          this.$router.push({ name: "trainer-inclass-assessment" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.isLoading = false;
        });

        

    //   await axios
    //     .post(
    //       `${this.baseUrl}admin/v2/trainer-inclass-assessment`,
    //       toSendData,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     .then(() => {
    //       this.toast.success("Successfully Inclass Assessment Created.");
    //       this.$router.push({ name: "trainer-inclass-assessment" });
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.error = error.response;
    //       this.toast.error("Something went wrong.");
    //       this.isLoading = false;
    //     });
    },
    async getInclassAssessmentById() {
        this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment/${this.$route.params.id}`
        )
        .then(async (response) => {
          const data = response.data.data;
          this.selectedProgram = data.program_name;
          this.selectedBatch = data.batch_no;
          this.selectedEmployee = data.employee_name;
            this.selectedLevel = data.level
            this.level = data.level
            this.inclass.program_id = data.program_id,
            this.inclass.program_batch_id = data.batch_id,
            this.inclass.employee_id = data.employee_id,
            this.inclass.join_date = data.join_date
          
            this.selectedWeight = data.assessment_criteria_id
            await this.getAllCriterias();

          if(data.trainer_criterias) {
            data.trainer_criterias.forEach((assessment) => {
            this.criterias.push({
              id: assessment.id,
              name: assessment.criteria_name,
              lowest_score: assessment.lowest_score,
              highest_score: assessment.highest_score,
              selectedScore: assessment.score,
              remark: assessment.remark,
            });
            this.updateTotalScore();
          });
          
          }
          
        //   this.getBatchByProgram(data.program_id);
        //   this.getEmployeeByBatch(data.program_batch_id);
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async updateTrainerInclassAssessment() {
        this.isLoading = true;
        let trainer_criterias = this.criterias.map((criteria) => {
        return {
            trainer_criteria_id: criteria.id,
            score: criteria.selectedScore,
            remark: criteria.remark,
            };
        });

        let toSendData = this.inclass;
        toSendData.trainer_criterias = trainer_criterias;

        await axios
            .put(
            `${this.baseUrl}admin/v2/trainer-assessment/${this.$route.params.id}`,toSendData)
            .then(() => {
            this.toast.success("Successfully InClass Assessment Updated.");
            this.$router.push({ name: "trainer-inclass-assessment" });
            this.isLoading = false;
            })
            .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
            });
    },
    async getExistedEmployees() {
      // this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainer-assessment?type=In-Class`
        )
        .then((response) => {
          this.existedEmployees = response?.data?.data?.filter(emp => this.currentUserId && emp.assessor_employee_id === this.currentUserId);;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
          // this.loading = false;
          // this.$Progress.finish();
        });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUserId() {
      return this.$store.state.auth.user.employee_id;
    },
  },

  async created() {
    this.getExistedEmployees();
    if (this.currentRouteName == "trainer-inclass-assessment-update") {
      await this.getInclassAssessmentById();
    }
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.scoreBox {
  width: 262px;
  margin-left: 12px;
}
</style>

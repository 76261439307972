<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "trainer-ojt-assessment-schedule-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <v-select
                        @option:selected="getSelectedProgram($event)"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                            'trainer-ojt-assessment-schedule-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error ||
                            errorFor('program name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('program name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <v-select
                        @option:selected="
                          existCheckLevel(selectedProgram, selectedBatch)
                        "
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                            'trainer-ojt-assessment-schedule-update'
                        "
                        :options="batches"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('batch name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('batch name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <v-select
                        @option:selected="getSelectedLevel($event)"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                            'trainer-ojt-assessment-schedule-update'
                        "
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedLevel.$error || errorFor('Level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('Level')"
                        :vuelidateErrors="{
                          errors: v$.selectedLevel.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Employee ID</th>
                      <th scope="col">
                        BU/Br/DIV <span class="text-danger">*</span>
                      </th>
                      <th scope="col">
                        Assessors <span class="text-danger">*</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(employee, index) in employeeLists" :key="index">
                      <td class="pt-2">{{ employee.employee_name }}</td>
                      <td class="pt-2">{{ employee.employee_id }}</td>
                      <td class="pt-2">
                        <v-select
                          @option:selected="getSelectedBuBr($event, index)"
                          v-model="employee.business_unit"
                          :disabled="
                            employee.trainer_assessment_details != null
                          "
                          label="name"
                          :options="bubrLists"
                          :selectable="
                            (options) => isSelectableBuBr(options, index)
                          "
                          :clearable="false"
                        >
                        </v-select>
                      </td>
                      <!-- :selectable="
                            (options) => isSelectable(options, index)
                          " -->
                      <td class="pt-2">
                        <v-select
                          @option:selected="
                            getSelectedBuBrAssessor($event, index)
                          "
                          :disabled="
                            employee.trainer_assessment_details != null
                          "
                          v-model="employee.assessor_employee_name"
                          label="name"
                          :options="allAssessors[index]"
                          :clearable="false"
                        >
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link
                      :to="{ name: 'trainer-ojt-assessment-schedule' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="employeeLists?.length == 0"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      data-bs-toggle="tooltip"
                      style="cursor: not-allowed"
                      title="There is no employees and assessors."
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-ojt-assessment-schedule-update'
                          ? updateOjtAssessmentSchedule()
                          : createOjtAssessmentSchedule()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import jsonp from "jsonp";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      i : 0,
      ojtAssessmentSchedule:[],
      check_level : [],
      programs: this.$store.getters["odoo/getAllPrograms"],
      batches: [],
      levels: [],
      bubrLists: [],
      employeeLists: [],
      assessorLists: [],
      allAssessors: {},
      bubrAssessorLists: [],
      existedEmployees: [],
      selectedProgram: null,
      selectedBatch: null,
      selectedLevel: [],
      selectedBuBr: null,
      selectedBuBrByAssessor: null,
      isLoading: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedLevel: { required },
      // selectedBuBr: { required },
      // selectedBuBrByAssessor: { required },
    };
  },
  methods: {
    async getOjtAssessmentSchedule() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/superior-assessment-schedule`)
        .then((response) => {
          this.ojtAssessmentSchedule = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      // $("#ojt-assessment-schedule-list-datatable").DataTable();
    },

    async getAllLevels() {
      await axios
        .get(`${this.baseUrl}admin/v1/levels`)
        .then((response) => {
          this.levels = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.employeeLists = [];
        this.selectedLevel = null;
        this.getBatchByProgram(this.selectedProgram.id);
      }
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
        )
        .then((response) => {
          this.batches = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
      this.loading = false;
      this.$Progress.finish();
    },

    async getSelectedLevel() {
      if (!this.selectedBatch || !this.selectedProgram || !this.selectedLevel)
        return;
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batch/employees/list/${this.selectedBatch.id}`
        )
        .then((response) => {
          const isCreateRoute = this.currentRouteName === "trainer-ojt-assessment-schedule-create"

          this.employeeLists = response.data.data.employee_ids.map((item) => {
            if(isCreateRoute && item.employee.active) {
            return {
              // employee: item.employee,
              employee_id: item.employee.emp_id,
              employee_name: item.employee.name,
              trainer_assessment_details: item.trainer_assessment_details,
            };
          }
          return null;
          })
          ?.filter(item => item !== null);
          // console.log(this.employeeLists)
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });

      if (this.i > 0) {
        this.check_level.pop(); // remove selectedLevel
      }
      this.check_level.push({ name: this.selectedLevel.name });
      this.i = 1;
    },

    async getAllBusinessUnits() {
      await axios
        .get(`${this.baseUrl}admin/v1/holdingbusiness`)
        .then((response) => {
          this.bubrLists = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Not Bu/Br found.");
        });
      // await this.getAssessorsByBuBr();
    },

    async getAssessorsByBuBr(index, id) {
      this.loading = true;
      this.$Progress.start();

      let i = index;

      await axios
        .get(`${this.baseUrl}admin/v1/employees/list?holding_id=${id}`)
        .then((response) => {
          this.bubrAssessorLists = response.data.data;
          this.allAssessors[i] = this.bubrAssessorLists?.filter(emp => emp.active);
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Not employee found.");
        });

      this.loading = false;
      this.$Progress.finish();
    },

    async getSelectedBuBr(val, index) {
      this.employeeLists[index].business_unit = val;
      this.selectedBuBr = val;
      // this.employeeLists
      this.getAssessorsByBuBr(index, val.id);
    },
    getSelectedBuBrAssessor(val, index) {
      this.selectedBuBrByAssessor = val;
      this.employeeLists[index].assessor_employee_id = val.emp_id;
      this.employeeLists[index].assessor_employee_name = val.name;
    },

    getSelectedBatch() {
      this.selectedLevel = null;
      this.employeeLists = [];
    },

    async createOjtAssessmentSchedule() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const isHasBusinessUnit = this.employeeLists?.some(
        (emp) => !emp.business_unit
      );

      if (isHasBusinessUnit) {
        return this.toast.error("Please fill all input.");
      }

      const toSendData = {
        program_id: this.selectedProgram.id,
        // program_name: this.selectedProgram.name,
        // program_short_name: this.selectedProgram.short_name,
        level: this.selectedLevel.name,
        batch_id: this.selectedBatch.id,
        // program_batch_no: this.selectedBatch.batch_no,
        // available: this.selectedBatch.available,
        // join_date: this.selectedBatch.join_date,
        employee_details: this.employeeLists.map((item) => {
          return {
            employee_id: item.employee_id,
            assessor_employee_id: item.assessor_employee_id,
            assessor_bu_id: item.business_unit.id,
          };
        }),
      };

      this.isLoading = true;
      // console.log(this.schedule, this.employeeLists)
      await axios
        .post(
          `${this.baseUrl}admin/v2/superior-assessment-schedule`,
          toSendData
        )
        .then(() => {
          this.$router.push({ name: "trainer-ojt-assessment-schedule" });
          this.toast.success(
            "Successfully created Superior Assessment Schedule."
          );
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateOjtAssessmentSchedule() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const isHasBusinessUnit = this.employeeLists?.some(
        (emp) => !emp.business_unit
      );

      if (isHasBusinessUnit) {
        return this.toast.error("Please fill all input.");
      }

      const toSendData = {
        program_id: this.selectedProgram.id,
        // program_name: this.selectedProgram.name,
        // program_short_name: this.selectedProgram.short_name,
        level: this.selectedLevel.name,
        batch_id: this.selectedBatch.id,
        // program_batch_no: this.selectedBatch.batch_no,
        // available: this.selectedBatch.available,
        // join_date: this.selectedBatch.join_date,
        employee_details: this.employeeLists.map((item) => {
          return {
            employee_id: item.employee_id,
            assessor_employee_id: item.assessor_employee_id,
            assessor_bu_id: item.business_unit.id,
          };
        }),
      };

      this.isLoading = true;
      // console.log(this.schedule, this.employeeLists)
      await axios
        .put(
          `${this.baseUrl}admin/v2/superior-assessment-schedule/${this.$route.params.id}`,
          toSendData
        )
        .then(() => {
          this.$router.push({ name: "trainer-ojt-assessment-schedule" });
          this.toast.success(
            "Successfully updated Superior Assessment Schedule."
          );
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async getOjtDetailById() {
      this.loading = true;
      this.$Progress.start();

      await axios
        .get(
          `${this.baseUrl}admin/v2/superior-assessment-schedule/${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data;
          this.selectedProgram = {
            id: data.program_id,
            name: data.program_name,
            // short_name: data.program_short_name,
          };
          this.getBatchByProgram(data.program_id);
          this.selectedLevel = { name: data.level };
          this.selectedBatch = {
            id: data.batch_id,
            batch_no: data.batch_no,
            // available: data.available,
            // join_date: data.join_date,
          };
          this.selectedBuBr = data.employee_details;
          this.selectedBuBrByAssessor = data.employee_details;
          this.employeeLists = data.employee_details.map((item) => {
            return {
              employee_id: item.employee_id,
              employee_name: item.employee_name,
              assessor_bu_id: item.assessor_bu_id,
              assessor_bu_name: item.assessor_bu_name,
              assessor_employee_id: item.assessor_employee_id,
              assessor_employee_name: item.assessor_employee_name,
              business_unit: {
                id: item.assessor_bu_id,
                name: item.assessor_bu_name,
              },
              trainer_assessment_details: item.trainer_assessment_details,
            };
          });

          this.employeeLists.forEach((item, index) => {
            this.getAssessorsByBuBr(index, item.business_unit.id);
          });
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
    },
    // async getExistedEmployee() {
    //   await axios
    //     .get(
    //       `${this.baseUrl}admin/v2/superior-assessment-schedule`
    //     )
    //     .then((response) => {
    //       this.existedEmployees = response.data.data;
    //     })
    //     .catch((error) => {
    //       this.error = error.response;
    //       this.toast.error("Something went wrong.");
    //     });
    // },
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if (!this.selectedBatch || !options) return false;

      return !this.check_level.some((level) => level.name == options.name);
    },
    isSelectableBuBr(options, index) {
      if (!this.employeeLists[index].business_unit || !options) return true;
      return this.employeeLists[index].business_unit.id != options.id;
    },
    // isSelectable(options, index) {
    //   if (this.employeeLists[index]) {
    //     return !this.existedEmployees.find(
    //       (item) =>
    //         item.assessor_employee_id == options.emp_id &&
    //         item.employee_id == this.employeeLists[index].employee_id &&
    //         item.ojt_assessment_schedule_mobile.level ==
    //           this.selectedLevel.name &&
    //         item.ojt_assessment_schedule_mobile.program_batch_id ==
    //           this.selectedBatch.id &&
    //         item.ojt_assessment_schedule_mobile.program_id ==
    //           this.selectedProgram.id
    //     );
    //   }
    // },
    existCheckLevel(program, batch) {
      this.i = 0;
      this.selectedLevel = null;
      this.employeeLists = [];
      this.check_level = [];
      this.ojtAssessmentSchedule.filter((val) => {
        if (program.id === val.program_id && batch.batch_no === val.batch_no) {
          this.check_level.push({ name: val.level });
        }
      });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "trainer-ojt-assessment-schedule-update") {
      await this.getOjtDetailById();
    }
    // await this.getExistedEmployee();
    this.getOjtAssessmentSchedule();
    this.getAllLevels();
    this.getAllBusinessUnits();
  },
};
</script>
<style scoped>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.trainer-form {
  width: 205px;
  height: 33px;
  margin-left: 5px;
}
.box {
  max-width: 1000px;
  /* overflow-x: auto; */
}
.box-width {
  width: 240px;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset;
  border-style: unset;
  border-width: unset;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex  justify-content-between align-items-center">
            <h4 class="page-title">Enrollment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex  flex-wrap gap-2 align-items-center">
              <div class="">
                <label class="col-form-label me-1">From Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="fetchData()"
                  ></flat-pickr>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">To Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="fetchData()"
                  ></flat-pickr>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">Module</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">Trainer</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <!-- <place-holder v-if="loading"></place-holder> -->
            <div class="table-responsive mt-4">
              <DataTable2 
                v-if="tableData.length > 0 && !loadingTable"
                :data="tableData"
                :columns="columns"
                :table="title"
                :loading="loadingTable"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                @update:pagination="handlePagination"
              >
              </DataTable2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
// import { months } from "../../../utlis/months";
// eslint-disable-next-line no-unused-vars
import DataTable2 from '@/components/shared/DataTable2.vue';
// eslint-disable-next-line no-unused-vars
import { exportExcel } from "../../../utlis/excel-export-already-formated-utlis";
import { exportPdf } from "../../../utlis/pdf-export-already-formated-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
    DataTable2
  },
  data() {
    return {
      years: [],
      months: [],
      modules: [],
      trainers: [],
      year: "",
      month: "",
      from_date: "",
      to_date: "",
      moduleName: "",
      trainer: "",
      employeeLists: [],
      title: "Enrollment List Report",
      tableData: [],
      columns: [
        { data: 'from_date', title: 'From Date' },
        { data: 'to_date', title: 'TO Date' },
        { data: 'module', title: 'Module Ttile' },
        { data: 'trainer_names', title: 'Trainer' },
        { data: 'enrollment_count', title: 'Enrollment Count' },
        { data: 'attendance_count', title: 'Attendance Count' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      searchQuery: '',
      searchTimeout: null,
      ignoreNextEmit: false,
      loadingTable: true,
      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      forExportingData: null,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },

  methods: {
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async fetchTableData({ currentPage, pageSize }) {
      this.loading = true;
      this.loadingTable = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/enrollment-list`, {
          params: {
            page: currentPage,
            per_page: pageSize,

            from_date: this.from_date ?? '',
            to_date: this.to_date ?? '',
            module_id: this.moduleName.id ?? '',
            trainer_id: this.trainer.emp_id ?? '',
          },
        });
        this.tableData = response.data.data;
        this.totalRecords = response.data.total;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
      } catch (error) {
        this.toast.error("Something went wrong.");
        console.error('Error fetching table data:', error);
      } finally {
        this.loading = false;
        this.loadingTable = false;
        this.$Progress.finish();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/enrollment-list`, {
          params: {
            from_date: this.from_date ?? '',
            to_date: this.to_date ?? '',
            module_id: this.moduleName.id ?? '',
            trainer_id: this.trainer.emp_id ?? '',
          },
        });
        if(response){
          return response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }

      return false;
    },


    fetchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      // this.searchQuery = '';
      this.fetchTableData({ currentPage: this.currentPage, pageSize: this.pageSize });
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
      console.log(this.currentPage, this.pageSize);
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.fetchTableData({ currentPage, pageSize });
      }
    },
    async exportPdfClick() {
      const apiData = await this.getWithoutPaginate();
      if(apiData){
        this.pdfLoading = true;
        exportPdf(apiData, this.columns, this.title);

        this.pdfLoading = false;
      }else{
        return this.toast.error("There is no data to export!");
      }
    },
    async exportExcelClick() {
      const apiData = await this.getWithoutPaginate();
      if (apiData) {
        this.excelLoading = true;

        exportExcel(apiData, this.columns, this.title);
        this.excelLoading = false;
      } else {
        return this.toast.error("There is no data to export!");
      }
    },
    refreshData() {
      this.from_date = "";
      this.to_date = "";
      this.moduleName = "";
      this.trainer = "";
      this.fetchData();
    },
  },
  async created() {
    if (this.$store.getters["odoo/getAllActiveEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllActiveEmployees"];
    }
    await this.getAllModules();
    await this.fetchData();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex align-items-center " >
            <div class="row mb-2 ">
              <label for="date" class="col-md-4 form-label">
                  From Date
                 
              </label>
              <div class="col-md-8">
                  <flat-pickr v-model="schedule.from_date" class="form-control"
                      @on-change="changeFromDate(schedule.from_date, schedule.to_date)"
                      placeholder="Select From Date" name="date" :config="flatPickrDateConfig">
                  </flat-pickr>
              </div>
            </div>
            <div class="row mb-2 ms-2">
                <label for="date" class="col-md-4 form-label">
                    To Date
                    
                </label>
                <div class="col-md-8">
                    <flat-pickr v-model="schedule.to_date" class="form-control"
                        @on-change="changeToDate(schedule.from_date, schedule.to_date)"
                        placeholder="Select To Date" name="date" :config="flatPickrDateConfig">
                    </flat-pickr>
                </div>
            </div>

            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">OJT Assessment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="d-flex justify-content-end">
                <router-link :to="{ name: 'hrd-ojt-assessment-create' }">
                  <button
                    v-show="!loading"
                    type="button"
                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                    v-if="hasPermissions(['module-group-create'])"
                  >
                    <i class="mdi mdi-plus-circle"></i> Add Record
                  </button>
                </router-link>
              </div> -->
            
            <!-- advance serach -->
            <div class="d-flex mb-3">
                      <div class="d-flex flex-column me-2">
                          <label class="col-form-label">Program</label>
                          <div style="width: 250px">
                              <v-select label="name" v-model="program" :options="programs" :clearable="false"
                                  :selectable="(options) => program.short_name != options.short_name "
                                  @option:selected="findBatch_programFilter(program)">
                              </v-select>
                          </div>
                      </div>
                      <div class="d-flex flex-column me-2">
                          <label class="col-form-label">Batch</label>
                          <div style="width: 180px">
                              <v-select label="batch_no" v-model="batch" :options="batchs" :clearable="false"
                              :selectable="(options) =>  batch.id != options.id "
                              @option:selected="FilterData(program, batch)"
                              >
                              </v-select>
                          </div>
                      </div>
                      <div class="d-flex mt-4">
                          <!-- <div class="col-form-label d-none">hfhd</div> -->
                          <div @click="refreshData()" class="icon-css">
                              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                          </div>
                      </div> 
                  </div>  
                <!--end advance serach -->  
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="ojt-assessment-list-datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>Level</th>
                    <th>Status</th>
                    <th>Achievement</th>
                    <th>Result</th>
                    <th>Assessor</th>
                    <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template v-for="(item, index) in ojtAssessment" :key="index">
                    <tr v-if="currentEmpId === item.assessor_employee_id">
                      <td>{{ ++index }}</td>
                      <td>{{ item.employee_name }}</td>
                      <td>{{ item.program_name }}</td>
                      <td>{{ item.batch_no }}</td>
                      <td>{{ item.level }}</td>
                      <td class="text-success" v-if="item.status == 'Completed'">
                        Completed
                      </td>
                      <td class="text-purple" v-else-if="item.status == 'todo'">TO DO</td>
                      <td class="text-warning" v-else-if="item.status == 'Onprogress'">Onprogress</td>
                      <td class="text-success" v-else>
                        {{item.status}}
                      </td>
                      <td>
                        {{ item.achievement == null ? "-" : item.achievement }}
                      </td>
                      <td>{{ item.result == null ? "-" : item.result }}</td>
                      <td>
                        {{ item.assessor_employee_name }}
                      </td>
                      <td v-if="hasPermissions(['module-group-edit'])">
                        <span v-if="item.status == 'Completed'">
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'hrd-ojt-assessment-getEditId',
                              params: {
                                id: item.id,
                              },
                            }"
                          >
                            <i class="mdi mdi-square-edit-outline"></i>
                          </router-link>
                        </span>
                        <span v-else>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'hrd-ojt-assessment-update',
                              params: {
                                id: item.id,
                                status: item.status,
                              },
                            }"
                          >
                            <i class="mdi mdi-plus-circle"></i>
                          </router-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { computed } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  components: {
    vSelect,
    flatPickr,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    const store = useStore();

    // Access user.employee_id using computed property
    const employeeId = computed(() => store.getters["auth/getAuthEmpId"]);

    return { toast, employeeId };
  },
  data() {
    return {
      currentEmpId: this.$store.getters["auth/getAuthEmpId"],
      count: 1,
      ojtAssessment: [],
      ass: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      programs: this.$store.getters["odoo/getAllPrograms"],
      batchs : [],
      program : "",
      batch : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
      flatPickrDateConfig: {
          minDate: null,
          maxDate: null,
          defaultDate: null,
      },
      flatPickrDateConfigLimit: {
          minDate: null,
          maxDate: null,
          defaultDate: null,
      },
      flatPickrTimeConfig: {
          enableTime: true,
          noCalendar: true,
          defaultTime: "08:00:00",
          dateFormat: "H:i:s",
          altInput: true,
          altFormat: "H:i",
          minTime: "08:00",
          maxTime: "17:00",
      },
      schedule: {
          from_date: "",
          to_date: "",
      },
    };
  },
  methods: {

    getIndex(index, innerIndex) {
      let currentIndex = 0;
      for (let i = 0; i < index; i++) {
        currentIndex += this.ojtAssessment[i].assessment_detail.length;
      }
      return currentIndex + innerIndex + 1;
    },
    async getAllAssessment() {
      this.loading = true;
      this.$Progress.start();

      let url;

      if(this.schedule.from_date && this.schedule.to_date){
        url = `${this.baseUrl}admin/v2/hrd-assessment?type=OJT&hrd_type=OJT&emp_id=${this.employeeId}&from_date=${this.schedule.from_date}&to_date=${this.schedule.to_date}`;
      }else if(this.schedule.from_date){
        url = `${this.baseUrl}admin/v2/hrd-assessment?type=OJT&hrd_type=OJT&emp_id=${this.employeeId}&from_date=${this.schedule.from_date}`;
      }else{
        url = `${this.baseUrl}admin/v2/hrd-assessment?type=OJT&hrd_type=OJT&emp_id=${this.employeeId}`;
      }

      await axios
        .get(
          url
        )
        // .get(`${this.baseUrl}admin/v2/hrd-ojt-assessment`)
        .then((response) => {
          // this.assessment = response.data.assessment;
          this.ojtAssessment = response.data.data;

          this.loading = false;
          this.$Progress.finish();
          // console.log(this.assessment);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#ojt-assessment-list-datatable").DataTable().draw();
    },

    async findBatch_programFilter(program) {
            this.batch = "";
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`)
                .then((response) => {
                    this.batchs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Batch!");
                });
    
                this.clearFilter();
                $.fn.dataTable.ext.search.push(function (settings, data) {
                    const check_program = program?.name == data[2] ? true : false;
                    console.log('checl-prog', check_program);
                    return check_program ? 1 : 0;
                });
                $("#ojt-assessment-list-datatable").DataTable().draw(); 
        },
    FilterData(program, batch) {
            this.clearFilter();
            // alert('reach');
            $.fn.dataTable.ext.search.push(function (settings, data) {
                const check_program = program?.name == data[2] ? true : false;
                const check_batch = batch?.batch_no == data[3] ? true : false;
                
                return (check_program && check_batch) ? true : false;
            });
            $("#ojt-assessment-list-datatable").DataTable().draw(); 
        },

    refreshData() {
      (this.ojtAssessment = []), (this.ass = []), (this.month = "");
      this.year = "";
      this.months = [];
      this.years = [];
      this.yearId = "";
      this.batchs = [];
      this.batch = "";
      this.program = "";
      this.schedule.from_date = '';
      this.schedule.to_date = '';
      this.clearFilter();
      this.getAllAssessment();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#ojt-assessment-list-datatable").DataTable().draw();
    },
    changeFromDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;
      this.getAllAssessment();
    },

    changeToDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;
      this.getAllAssessment();
    },
  },
  created() {
    this.clearFilter();
    this.getAllAssessment();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
.status {
  padding: 0.4em;
}
</style>

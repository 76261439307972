<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0"></ol>
          </div>
          <h4 class="page-title">Training Attendance Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="row gutter-css" v-if="!loading">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 custom-border">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Title</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Type</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.employee_type.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Group</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.group.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">From Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.from_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">To Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.to_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Enrolled Expired Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.expired_date
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                    <div class="col-xl-6">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Training Hours</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.duration
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Trainer</span>
                              </th>
                              <td>
                                <!-- <v-select
                                      label="name"
                                      multiple
                                      v-model="selectedTrainer.name"
                                      :options="monthly_plan.trainers"
                                      :reduce="(name) => name.id"
                                      @option:selected="getTrainer()"
                                    >
                                    </v-select> -->

                                <v-select
                                  style="width: 200px"
                                  v-if="monthly_plan.attendance_status == 2"
                                  multiple
                                  @option:selected="getSelectedTrainer"
                                  v-model="selectedTrainer"
                                  label="name"
                                  :options="monthly_plan.trainers"
                                  :clearable="false"
                                  disabled
                                  data-bs-toggle="tooltip"
                                  title="'You can not change status if attendance plan status is confirmed!'"
                                >
                                </v-select>
                                <v-select
                                  style="width: 200px"
                                  multiple
                                  v-else
                                  @option:selected="getSelectedTrainer"
                                  v-model="selectedTrainer"
                                  label="name"
                                  :options="monthly_plan.trainers"
                                  :clearable="false"
                                >
                                </v-select>

                                <!-- style="display: block" -->
                                <!-- <span
                                  class="ms-1"
                                  v-for="(
                                    trainer, index
                                  ) in monthly_plan.trainers"
                                  :key="index"
                                  >{{ trainer.name }}
                                  <span
                                    v-if="
                                      index + 1 < monthly_plan.trainers.length
                                    "
                                    >,
                                  </span>
                                </span> -->
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Location</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.location
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Batch</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.batch
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th class="status-top">
                                <span class="ms-1">Status</span>
                              </th>
                              <td>
                                <div style="width: 200px; padding-left: 6px">
                                  <!-- Normal -->
                                  <div
                                    v-if="
                                      monthly_plan.employee_type.name ==
                                      'Normal'
                                    "
                                  >
                                    <v-select
                                      v-if="monthly_plan.attendance_status == 2"
                                      id="vue-selectCss"
                                      v-model="plan_attendance_status"
                                      label="name"
                                      :options="attendance_status_list"
                                      disabled
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                      data-bs-toggle="tooltip"
                                      :title="
                                        monthly_plan.attendance_status == 2
                                          ? 'You can not change status if attendance plan status is confirmed!'
                                          : 'You can not change status after end of the related month!'
                                      "
                                    >
                                    </v-select>
                                    <!-- <v-select v-else id="vue-selectCss" v-model="plan_attendance_status" label="name"
                                      :disabled="
                                        this.$store.state.auth.user.roles[0] ==
                                          'Admin'
                                          ? false
                                          : true
                                      " :options="attendance_status_list" :selectable="
  (option) =>
    !option.name.includes('Unprocessed')
" :reduce="(name) => name.id" :clearable="false" data-bs-toggle="tooltip" :title="
  this.$store.state.auth.user.roles[0] ==
    'Admin'
    ? ''
    : 'You can not change status!'
">
                                    </v-select> -->
                                    <v-select
                                      v-else
                                      id="vue-selectCss"
                                      v-model="plan_attendance_status"
                                      label="name"
                                      :options="attendance_status_list"
                                      :selectable="
                                        (option) =>
                                          !option.name.includes('Unprocessed')
                                      "
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                    >
                                    </v-select>
                                  </div>
                                  <!-- Program -->
                                  <div v-else>
                                    <!-- <v-select id="vue-selectCss" v-model="plan_attendance_status" label="name" :disabled="this.$store.state.auth.user.roles[0] ==
                                          'Admin'
                                          ? false
                                          : true
                                        " :options="attendance_status_list" :selectable="(option) =>
          !option.name.includes('Unprocessed')
        " :reduce="(name) => name.id" :clearable="false" data-bs-toggle="tooltip" :title="this.$store.state.auth.user.roles[0] ==
          'Admin'
          ? ''
          : 'You can not change status!'
        ">
                                    </v-select> -->
                                    <v-select
                                      id="vue-selectCss"
                                      v-model="plan_attendance_status"
                                      label="name"
                                      v-if="monthly_plan.attendance_status == 2"
                                      :options="attendance_status_list"
                                      data-bs-toggle="tooltip"
                                      title="You can not change status if attendance plan status is confirmed!"
                                      disabled
                                      :selectable="
                                        (option) =>
                                          !option.name.includes('Unprocessed')
                                      "
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                    >
                                    </v-select>
                                    <v-select
                                      v-else
                                      id="vue-selectCss"
                                      v-model="plan_attendance_status"
                                      label="name"
                                      :options="attendance_status_list"
                                      :selectable="
                                        (option) =>
                                          !option.name.includes('Unprocessed')
                                      "
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                    >
                                    </v-select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs" v-if="!loading">
              <li class="nav-item d-flex">
                <a
                  data-bs-toggle="tab"
                  aria-expanded="false"
                  class="nav-link"
                  :key="index"
                  :class="{ active: isactive == index }"
                  @click="tab(dateItem, index)"
                  v-for="(dateItem, index) in dateArray"
                >
                  {{ dateItem }}
                </a>
              </li>
            </ul>

            <place-holder v-if="attendance_loading"></place-holder>
            <div class="table-responsive tab-content pt-0">
              <table
                class="table table-centered mb-0"
                id="training_attendance_datatable"
                v-if="!attendance_loading"
              >
                <thead class="table-light">
                  <tr>
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Position Level</th>
                    <th>Department</th>
                    <th>BU/BR/DIV</th>
                    <th>Attendance Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in filterAttendanceList"
                    :key="index"
                  >
                    <template v-if="item?.emp_id">
                      <td>{{ ++index }}</td>
                    <td>{{ item.emp_id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.position_name }}</td>
                    <td>{{ item.department_name }}</td>
                    <td>{{ item.business_unit_name }}</td>
                    <td>
                      <div style="width: 150px">
                        <!-- Normal -->
                        <div v-if="monthly_plan.employee_type.name == 'Normal'">
                          <v-select
                            v-if="monthly_plan.attendance_status == 2"
                            id="lastChildCSS"
                            v-model="item.is_present"
                            label="name"
                            :options="attendance_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                            disabled
                            data-bs-toggle="tooltip"
                            :title="
                              monthly_plan.attendance_status == 2
                                ? 'You can not change status if attendance plan status is confirmed!'
                                : 'You can not change status after end of the related month!'
                            "
                          >
                          </v-select>
                          <!-- <v-select v-else id="lastChildCSS" v-model="item.is_present" label="name" :disabled="this.$store.state.auth.user.roles[0] == 'Admin'
                              ? false
                              : true
                            " :options="attendance_status" :reduce="(name) => name.id" :clearable="false"
                            data-bs-toggle="tooltip" :title="this.$store.state.auth.user.roles[0] == 'Admin'
                                ? ''
                                : 'You can not change status!'
                              ">
                          </v-select> -->
                          <v-select
                            v-else
                            id="lastChildCSS"
                            v-model="item.is_present"
                            label="name"
                            :options="attendance_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                          >
                          </v-select>
                        </div>
                        <!-- Program -->
                        <div v-else>
                          <!-- <v-select id="lastChildCSS" v-model="item.is_present" label="name" :disabled="this.$store.state.auth.user.roles[0] == 'Admin'
                              ? false
                              : true
                            " :options="attendance_status" :reduce="(name) => name.id" :clearable="false"
                            data-bs-toggle="tooltip" :title="this.$store.state.auth.user.roles[0] == 'Admin'
                                ? ''
                                : 'You can not change status!'
                              ">
                          </v-select> -->
                          <v-select
                            v-if="monthly_plan.attendance_status == 2"
                            id="lastChildCSS"
                            v-model="item.is_present"
                            label="name"
                            data-bs-toggle="tooltip"
                            title="You can not change status if attendance plan status is confirmed!"
                            disabled
                            :options="attendance_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                          >
                          </v-select>
                          <v-select
                            v-else
                            id="lastChildCSS"
                            v-model="item.is_present"
                            label="name"
                            :options="attendance_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                          >
                          </v-select>
                        </div>
                      </div>
                    </td>
                    </template>
                  </tr>
                </tbody>
              </table>
              <div class="text-center mt-3 mb-3" v-if="!attendance_loading">
                <router-link
                  :to="{
                    name: 'training-attendance',
                  }"
                >
                  <button type="button" class="btn w-sm btn-secondary me-5">
                    Cancel
                  </button>
                </router-link>
                <!-- Normal -->
                <div
                  v-if="monthly_plan.employee_type.name == 'Normal'"
                  class="submit-button"
                >
                  <div v-if="monthly_plan.attendance_status == 2">
                    <button
                      type="button"
                      style="cursor: not-allowed"
                      class="btn w-sm btn-success waves-effect waves-light"
                      data-bs-toggle="tooltip"
                      :title="
                        monthly_plan.attendance_status == 2
                          ? 'You can not change status if attendance plan status is confirmed!'
                          : 'You can not change status after end of the related month!'
                      "
                    >
                      Submit
                    </button>
                  </div>
                  <!-- <div v-else>
                    <button v-if="this.$store.state.auth.user.roles[0] == 'Admin'"
                      @click="updateMonthlyTrainingAttendance" type="button"
                      class="btn w-sm btn-success waves-effect waves-light">
                      Submit
                    </button>
                    <button v-else type="button" style="cursor: not-allowed"
                      class="btn w-sm btn-success waves-effect waves-light" data-bs-toggle="tooltip"
                      title="You can not change status!">
                      Submit
                    </button>
                  </div> -->
                  <div v-else>
                    <button
                      :disabled="isLoading ? true : false"
                      @click="updateMonthlyTrainingAttendance"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Submit" }}
                    </button>
                  </div>
                </div>
                <!-- Program -->
                <!-- <div v-else class="submit-button">
                  <button v-if="this.$store.state.auth.user.roles[0] == 'Admin'" @click="updateMonthlyTrainingAttendance"
                    type="button" class="btn w-sm btn-success waves-effect waves-light">
                    Submit
                  </button>
                  <button v-else type="button" style="cursor: not-allowed"
                    class="btn w-sm btn-success waves-effect waves-light" data-bs-toggle="tooltip"
                    title="You can not change status!">
                    Submit
                  </button>
                </div> -->

                <div class="submit-button" v-else>
                  <button
                    v-if="monthly_plan.attendance_status == 2"
                    :disabled="isLoading ? true : false"
                    style="cursor: not-allowed"
                    data-bs-toggle="tooltip"
                    title="You can not change status if attendance plan status is confirmed!"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ isLoading == true ? "Loading..." : "Submit" }}
                  </button>

                  <button
                    v-else
                    :disabled="isLoading ? true : false"
                    @click="updateMonthlyTrainingAttendance"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ isLoading == true ? "Loading..." : "Submit" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Datatable TrainingAttendanceList
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import $ from "jquery";
import axios from "axios";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedTrainer: null,
      loading: false,
      isLoading: false,
      attendance_loading: false,
      monthly_plan: "",
      current_date: "",
      end_of_month_date: "",
      plan_attendance_status: "",
      attendance_status_list: [
        {
          id: 0,
          name: "Unprocessed",
        },
        {
          id: 1,
          name: "Onprogress",
        },
        {
          id: 2,
          name: "Confirmed",
        },
      ],
      dateArray: "[]",
      isactive: "",
      isAtiveDate: "",
      employees: this.$store.getters["odoo/getAllEmployees"],
      trainingAttendanceList: "",
      filterAttendanceList: [],
      attendance_status: [
        {
          id: 0,
          name: "Absent",
        },
        {
          id: 1,
          name: "Present",
        },
      ],
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },

  methods: {
    getSelectedTrainer() {},
    async getMonthlyPlanDetail() {
      this.loading = true;
      this.attendance_loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/training-attendances-status-details/${this.$route.params.id}`
        )
        .then((response) => {
          this.monthly_plan = response.data.data;
          this.plan_attendance_status = this.monthly_plan.attendance_status;
          if (this.monthly_plan.attendance_status == 2) {
            this.selectedTrainer = this.monthly_plan.trainers;
          } else {
            this.selectedTrainer = this.monthly_plan.trainers;
          }

          this.end_of_month_date = moment(this.monthly_plan.to_date)
            .clone()
            .endOf("month")
            .format("YYYY-MM-DD");

          function getDatesInRange(startDate, endDate) {
            const date = new Date(startDate.getTime());
            const dates = [];
            while (date <= endDate) {
              dates.push(moment(new Date(date)).format("YYYY-MM-DD"));
              date.setDate(date.getDate() + 1);
            }
            return dates;
          }
          const d1 = new Date(this.monthly_plan.from_date);
          const d2 = new Date(this.monthly_plan.to_date);
          this.dateArray = getDatesInRange(d1, d2);

          this.trainingEnrollAttendanceListByDate();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
        });
    },
    tab(dateItem, index) {
      this.isactive = index;
      this.isAtiveDate = dateItem;

      this.trainingEnrollAttendanceListByDate();
      this.trainingAttendanceList = "";
      this.filterAttendanceList = "";
    },
    getTrainer(value) {
      this.selectedTrainer = value.name;
    },
    async getEmployees() {
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    async updateMonthlyTrainingAttendance() {
      let updateForm = new FormData();

      updateForm.append("attendance_status", this.plan_attendance_status);

      updateForm.append("monthly_plan_id", this.$route.params.id);

      if (this.isAtiveDate) {
        updateForm.append("attendance_date", this.isAtiveDate);
      } else {
        updateForm.append("attendance_date", this.dateArray[0]);
      }

      var result = [];
      this.filterAttendanceList.forEach((employeeData, index) => {
        if (employeeData.is_present == null) {
          employeeData.is_present = 1;
        }
        var data = {
          enrolled_user_id: ++index,
          employee_id: employeeData.emp_id,
          is_present: employeeData.is_present,
        };
        result.push(data);
      });
      updateForm.append("attendance_list", JSON.stringify(result));

      if (!Array.isArray(this.selectedTrainer))
        this.selectedTrainer = [this.selectedTrainer];

      updateForm.append("trainer_list", JSON.stringify(this.selectedTrainer));

      this.isLoading = true;

      await axios
        .post(
          `${this.baseUrl}admin/v1/training-enrolls-attendances/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "training-attendance" });
          this.toast.success("Successfully Training Attendance Updated!");
          this.isLoading = false;
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    async trainingEnrollAttendanceListByDate() {
      await this.getEmployees();
      this.attendance_loading = true;
      this.$Progress.start();

      var activeCurrentDate = "";
      if (this.isAtiveDate) {
        activeCurrentDate = this.isAtiveDate;
      } else {
        activeCurrentDate = this.dateArray[0];
      }
      await axios
        .get(
          `${this.baseUrl}admin/v1/training-enrolls-attendances/${this.$route.params.id}/${activeCurrentDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.trainingAttendanceList = response.data.data;

          if (this.trainingAttendanceList.length > 0) {
            this.filterAttendanceList = this.trainingAttendanceList.map(
              (list) => {
                let employee = this.checklist(list.employee_id);
                if(!employee) return;
                let obj = {
                  emp_id: employee.emp_id,
                  name: employee.name,
                  position_name: employee.position_level.name,
                  department_name: employee.department.name,
                  business_unit_name: employee.business_unit.name,
                  is_present: list.is_present,
                };
                return obj;
              }
            );
            this.attendance_loading = false;
            this.$Progress.finish();
          } else {
            this.getAllMonthlyTrainingDetail();
          }
        });
    },
    checklist(empId) {
      let resultArray;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },
    async getAllMonthlyTrainingDetail() {
      // await this.getEmployees();
      this.attendance_loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/training-enrolls-attendances-details/${this.$route.params.id}`
        )
        .then((response) => {
          this.trainingAttendanceList = response.data.data;

          this.filterAttendanceList = this.trainingAttendanceList.map(
            (list) => {
              let employee = this.checklist(list.enrolled_user_id);
              if (list.is_present == null) {
                list.is_present = 1;
              }
              if(employee) {
                let obj = {
                emp_id: employee.emp_id,
                name: employee.name,
                position_name: employee.position_level.name,
                department_name: employee.department.name,
                business_unit_name: employee.business_unit.name,
                is_present: list.is_present,
              };
              return obj;
              }
              return;
            }
          );
        });
      this.attendance_loading = false;
      this.$Progress.finish();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#training_attendance_datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getMonthlyPlanDetail();
    this.current_date = moment().format("YYYY-MM-DD");
    // this.getEmployees();
  },
  components: {
    vSelect,
  },
};
</script>

<style>
#vue-selectCss ul {
  position: relative !important;
}

tr:last-child #lastChildCSS ul {
  position: relative !important;
}
</style>

<style scoped>
.active {
  color: #1abc9c !important;
}

.custom-tr {
  border-style: hidden !important;
}

.status-top {
  vertical-align: inherit !important;
}

.card {
  border: 1px solid #ccc !important;
  margin-bottom: 40px !important;
}

.custom-border {
  border-right: 1px solid #ccc !important;
  height: auto;
}

.gutter-css {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.submit-button {
  position: relative;
  cursor: pointer;
  display: inline-block;
  /* overflow: hidden; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  overflow-x: visible;
  overflow-y: hidden;
}

.nav-link {
  width: 115px;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">ATA Summary Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Module Name</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="module"
                    :options="modules"
                    :reduce="(val)=> val.id"
                    :clearable="false"
                    :selectable="(options) => options.id !== module"
                    :loading="!modules.length"
                
                  >
                  </v-select>
                </div>
              </div>
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Assessor Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employees"
                    :reduce="val => val.emp_id"
                    :clearable="false"
                    :selectable="(options) => options.id != employee.id"
                    :loading="!employees.length"
                  >
                  </v-select>
                </div>
              </div>
              <div @click="refreshData" class="icon-css pt-4 mt-2">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">

              <DataTable2 
                :data="assessment_result"
                :columns="columns"
                :table="'ATA-Summary-Report'"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :width="true"
                @update:pagination="handlePagination"
              >
                <template v-slot:column-module_name="{ row }">
                    {{ row.assessment_before_one_weeks.monthly_plan.module.name }}
                </template>
                <template v-slot:column-assessor_name="{ row }">
                    {{ row.assessment_before_one_weeks?.assessor?.name }}
                </template>
                <template v-slot:column-trainee_name="{ row }">
                     {{ row.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name }}
                </template>
                <template v-slot:column-date="{ row }">
                    {{ row.assessment_before_one_weeks?.training_date }}
                </template>
                <template v-slot:column-score="{ row }">
                    {{ assessmentScore(row.assessment_result_answer) }}
                </template>
              </DataTable2>
    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import DataTable2 from '@/components/shared/DataTable2.vue';


export default {
  components: {
    badgeSuccess,
    badgeDanger,
    vSelect,
    DataTable2
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_result: [],
      exportData: [],
      employees: this.$store.getters["odoo/getAllActiveEmployees"],
      employee: "",
      modules: [],
      module: "",
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      title: "ATA Summary Report Lists",
      headers: [
        "No.",
        "Module Name",
        "Assessor Name",
        "Trainee Name",
        "Training Date",
        "Assessment Score",
        "If you choose 60% and below, please write detailed reason.",
        "Suggestion for training team",
        "Next Improved Training Titles for your subordinate",
      ],
      columns: [
        { data: 'module_name', title: 'Module Name'},
        { data: 'assessor_name', title: 'Assessor Name' },
        { data: 'trainee_name', title: 'Trainee Name' },
        { data: 'date', title: 'Training Date' },
        { data: 'score', title: 'Assessment Score' },
        { data: 'above_target_mark_suggestion', title: 'If you choose 60% and below, please write detailed reason.' },
        { data: 'team_suggestion', title: 'Suggestion for training team' },
        { data: 'next_improved_training_title', title: 'Next Improved Training Titles for your subordinate' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      // searchQuery: '',
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllModules() {
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.$Progress.finish();
      });
    },
    async getAssessmentResult() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/ata-summary-report`,{
            params : {
                page: this.currentPage,
                per_page: this.pageSize,
                assessor_id : this.employee, 
                module_id : this.module
            }
        })
        .then((response) => {
          this.assessment_result = response.data.data.data;
          this.totalRecords = response.data.total;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
    assessmentScore(question_list) {
      let score = 0;
      let length = 0;
      for (let data of question_list) {
        if (data.question_name?.question_type == "radio") {
          length++;
          switch (data.answer) {
            case "Strongly Agree":
              score += 100;
              break;
            case "Agree":
              score += 80;
              break;
            case "Disagree":
              score += 60;
              break;
            case "Strongly Disagree":
              score += 40;
              break;
            default:
              score += 0;
              break;
          }
        }
      }
      const average_score = Math.floor(score / length);
      return average_score + "%";
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
    
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.getAssessmentResult();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/ata-summary-report`, {
            params : {
                assessor_id : this.employee, 
                module_id : this.module
            }
        });
        if(response){
            this.exportData = response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }
    },
    async exportPdfClick() {

      await this.getWithoutPaginate();

      if (this.exportData.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = this.exportData.map((item, index) => {
            let row = [];
            row[0] = index + 1;
            row[1] = item.assessment_before_one_weeks?.monthly_plan?.module?.name;
            row[2] = item.assessment_before_one_weeks?.assessor.name;
            row[3] = item.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name;
            row[4] = item.assessment_before_one_weeks?.training_date;
            row[5] = this.assessmentScore(item.assessment_result_answer);
            row[6] = item.above_target_mark_suggestion;
            row[7] = item.team_suggestion
            row[8] = item.next_improved_training_title
        
          return row;
        });    
    
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },

    async exportExcelClick() {

      await this.getWithoutPaginate();
      if (this.exportData.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
    
      const sheetData = [this.headers];
      
      this.exportData.forEach((item, index) => {
          sheetData.push([
           index + 1,
           item.assessment_before_one_weeks?.monthly_plan?.module?.name,
           item.assessment_before_one_weeks?.assessor.name,
           item.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name,
           item.assessment_before_one_weeks?.training_date,
           this.assessmentScore(item.assessment_result_answer),
           item.above_target_mark_suggestion,
           item.team_suggestion,
           item.next_improved_training_title
          ]);
        });    
      exportExcel(sheetData, this.title);
      this.excelLoading = false;
    },

    refreshData() {
      this.employee = "";
      this.module = "";
      this.currentPage = 1;
      this.pageSize = 10;
      this.getAssessmentResult();
    },
  },
  watch: {
    module(module_id) {
        if(module_id){
            this.getAssessmentResult();
        }
    },
    employee(emp_id) {
        if(emp_id) {
            this.getAssessmentResult();
        }
    }
  },
  created() {
    this.getAssessmentResult();
    this.getAllModules();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>

import { HTTP } from "../../service/http-common";
// initial state
const state = () => ({
  employees: [],
  activeEmployees: [],
  programs: [],
  isloading: false,
});

// getters
const getters = {
  getAllEmployees(state) {
    return state.employees;
  },
  getAllActiveEmployees(state) {
    return state.activeEmployees;
  },
  getAllPrograms(state) {
    return state.programs;
  },
  getLoading(state) {
    return state.isloading;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  fetchAllEmployees(state) {
    return new Promise((resolve, reject) => {
      state.isloading = true;
      HTTP.get("admin/v1/employees")
        .then((response) => {
          state.employees = response.data.data;
          state.activeEmployees = response?.data?.data?.filter(emp => emp.active)
          resolve(state.employees);
          state.isloading = false;
          // this.fetchLoading(state);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllPrograms(state) {
    return new Promise((resolve, reject) => {
      state.isloading = true;
      HTTP.get("admin/v1/programs")
        .then((response) => {
          state.programs = response.data.data;
          resolve(state.programs);
          state.isloading = false;
          // this.fetchLoading(state);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // fetchLoading(state) {
  //   state.isloading = false;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

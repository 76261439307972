<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Evaluation Suggestion List Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <!-- <div class="d-flex"> -->

              <div>
                <label class="col-form-label ms-1 me-1">Module</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName?.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Trainer Name</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer?.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Month</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="month"
                    :options="allMonths"
                    :clearable="false"
                    :selectable="(options) => options.id != month?.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
              <!-- </div> -->
            </div>
            <div class="flex flex-row-reverse d-flex align-items-center">
              <svg @click="searchQuery = ''" v-if="searchQuery" xmlns="http://www.w3.org/2000/svg" fill="gray" class="icon-css text-secondary border p-1" style="width: 38px; height: 38px; color: dimgray;" viewBox="0 0 384 512">
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
              </svg>
              <!-- <i style="color: #4a81d4" class="fa-solid fa-xmark"></i> -->
              <input 
                name="search"
                id="search"
                type="text"  
                class="form-control ms-2" style="width: 200px;" 
                v-model="searchQuery"/>

                Search :
            </div>
            <!-- <place-holder v-if="loading"></place-holder> -->
            <div class="table-responsive mt-4" >
              <DataTable2 
                v-if="tableData.length > 0 && !loadingTable"
                :data="tableData"
                :columns="columns"
                :table="title"
                :loading="loadingTable"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                @update:pagination="handlePagination"
              >
              </DataTable2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-already-formated-utlis";
import { exportPdf } from "../../../utlis/pdf-export-already-formated-utlis";
import userHasPermissions from "../../../mixins/userHasPermissions";
import DataTable2 from '@/components/shared/DataTable2.vue';

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
    DataTable2
  },
  data() {
    return {
      moduleName: "",
      trainer: "",
      month: "",
      allEvaluation: [],
      employeeLists: this.$store.getters["odoo/getAllActiveEmployees"],
      modules: [],
      allMonths: [],
      tableData: [],
      columns: [
        { data: 'module_name', title: 'Module Name' },
        { data: 'suggestion', title: 'Suggestion' },
        { data: 'trainer_name', title: 'Trainer' },
        { data: 'start_date', title: 'Start Date' },
        { data: 'end_date', title: 'End Date' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      searchQuery: '',
      searchTimeout: null,
      ignoreNextEmit: false,
      loadingTable: true,
      title: "Evaluation Suggestion Report",
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  watch: {
    searchQuery() {
      // Clear the previous timeout if there was one
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout
      this.searchTimeout = setTimeout(() => {
        this.fetchData();
      }, 1000); // 
    }
  },
  methods: {
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async fetchTableData({ currentPage, pageSize }) {
      this.loading = true;
      this.loadingTable = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/evaulation-suggestion-report`, {
          params: {
            page: currentPage,
            per_page: pageSize,

            trainer_id: this.trainer.emp_id ?? '',
            module_id: this.moduleName.id ?? '',
            month: this.month.id ?? '',
            search: this.searchQuery,

          },
        });
        this.tableData = response.data.data;
        this.totalRecords = response.data.total;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
      } catch (error) {
        this.toast.error("Something went wrong.");
        console.error('Error fetching table data:', error);
      } finally {
        this.loading = false;
        this.loadingTable = false;
        this.$Progress.finish();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/evaulation-suggestion-report`, {
          params: {
            module_id: this.moduleName.id ?? '',
            trainer_id: this.trainer.emp_id ?? '',
            month: this.month.id ?? '',
            search: this.searchQuery,

          },
        });
        if(response){
          return response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }

      return false;
    },


    fetchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      // this.searchQuery = '';
      this.fetchTableData({ currentPage: this.currentPage, pageSize: this.pageSize });
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
      console.log(this.currentPage, this.pageSize);
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.fetchTableData({ currentPage, pageSize });
      }
    },
    async exportPdfClick() {
      const apiData = await this.getWithoutPaginate();
      if(apiData){
        this.pdfLoading = true;
        exportPdf(apiData, this.columns, this.title);

        this.pdfLoading = false;
      }else{
        return this.toast.error("There is no data to export!");
      }
    },
    async exportExcelClick() {
      const apiData = await this.getWithoutPaginate();
      if (apiData) {
        this.excelLoading = true;

        exportExcel(apiData, this.columns, this.title);
        this.excelLoading = false;
      } else {
        return this.toast.error("There is no data to export!");
      }
    },
    refreshData() {
      this.moduleName = "";
      this.trainer = "";
      this.month = "";
      this.searchQuery = "";
      this.fetchData();
    },
  },
  async created() {
    this.allMonths = months;
    this.getAllModules();
    await this.fetchData();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>

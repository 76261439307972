<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="page-title">Achievement Detail Report</h4>
              <div>
                <button
                  class="ms-3 btn btn-danger btn-sm"
                  @click="exportPdfClick()"
                >
                  <span
                    v-if="pdfLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
                </button>
                <button
                  class="ms-2 btn btn-success btn-sm"
                  @click="exportExcelClick()"
                >
                  <span
                    v-if="excelLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ excelLoading == true ? "Loading..." : "Export Excel" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-4">
                <div class="me-2">
                  <label class="col-form-label ms-1 me-1">Module Name</label>
                  <div style="width: 250px">
                    <v-select
                      label="name"
                      v-model="module"
                      :options="modules"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      :selectable="(options) => options.id !== module"
                      :loading="!modules.length"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="me-2">
                  <label class="col-form-label ms-1 me-1">Assessor Name</label>
                  <div style="width: 200px">
                    <v-select
                      label="name"
                      v-model="employee"
                      :reduce="(val) => val.emp_id"
                      :options="employees"
                      :clearable="false"
                      :selectable="(options) => options.emp_id != employee"
                      :loading="!employees.length"
                    >
                    </v-select>
                  </div>
                </div>
        
                <div class="me-2">
                  <label class="col-form-label ms-1 me-1">Status</label>
                  <div style="width: 150px">
                    <v-select
                      label="name"
                      v-model="status"
                      :options="statusList"
                      :clearable="false"
                      :reduce="(data)=> data.value"
                      :selectable="(options) => options.value !== status"
                    >
                    </v-select>
                  </div>
                </div>
                <div @click="refreshData" class="icon-css pt-4 mt-2">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
              <place-holder v-if="loading"></place-holder>
              <div class="table-responsive" v-if="!loading">
        
                <DataTable2 
                  :data="assessment_result"
                  :columns="columns"
                  :table="'ATA-Report'"
                  :totalRecords="totalRecords"
                  :currentPage="currentPage"
                  :pageSize="pageSize"
                  :width="true"
                  :loading = "loading"
                  @update:pagination="handlePagination"
                >
                  <template v-slot:column-module="{ row }">
                      {{ row.assessment_before_one_weeks?.monthly_plan?.module?.name }}
                  </template>
                  <template v-slot:column-date="{ row }">
                      {{ row.assessment_before_one_weeks?.training_date }}
                  </template>
                  <template v-slot:column-assessor_name="{ row }">
                      {{ row.assessment_before_one_weeks?.assessor?.name }}
                  </template>
                  <template v-slot:column-business_unit="{ row }">
                      {{  checkBusinessUnit(row?.assessment_before_one_weeks?.assessor_id)  }}
                  </template>
                  <template v-slot:column-assessment_due_date="{ row }">
                      {{  assessmentDate(row.assessment_before_one_weeks.assessment_due_date) }}
                  </template>
                  <template v-slot:column-dead_line="{ row }">
                      {{  row.assessment_before_one_weeks.assessment_due_date }}
                  </template>
                  <template v-slot:column-finished_date="{ row }">
                      {{  finishedDate(row.created_at, row.updated_at) }}
                  </template>
                  <template v-slot:column-status="{ row }">
                      {{  checkStatus(row.status) }}
                  </template>    
                  <template v-slot:column-trainee_name="{ row }">
                       {{ row.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name }}
                  </template>
                  
                </DataTable2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //Datatable Program Timeline
  
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
  import badgeDanger from "../../../components/shared/badgeDanger.vue";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  //Mixins
  import userHasPermissions from "../../../mixins/userHasPermissions";
  import { exportExcel } from "../../../utlis/excel-export-utlis";
  import { exportPdf } from "../../../utlis/pdf-export-utlis";
  import DataTable2 from '@/components/shared/DataTable2.vue';
  
  
  export default {
    components: {
      badgeSuccess,
      badgeDanger,
      vSelect,
      DataTable2
    },
    mixins: [userHasPermissions],
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        assessment_result: [],
        exportData : [],
        employees: this.$store.getters["odoo/getAllActiveEmployees"],
        employee: "",
        modules: [],
        module: "",
        status: "",
        state: "",
        params : {
                  page: this.currentPage,
                  per_page: this.pageSize,
                  assessor_id : this.employee, 
                  module_id : this.module,
              },
        statusList: [{name:"Complete", value:2}, {name:"Expired", value:3}, {name:"Waiting", value:1}],
        stateList: ["Active", "Expire"],
        title: "Achievement Detail Report Lists",
        headers: [
          "No.",
          "Module Name",
          "Training Date",
          "Assessor Name",
          "Business Unit",
          "Assessment date",
          "Due Date(Deadline)",
          "Assessor Finished Date",
          "State",
          "Status",
          "Trainees Name",
        ],
        columns: [
          { data: 'module', title: 'Module Name'},
          { data: 'date', title: 'Training Date'},
          { data: 'assessor_name', title: 'Assessor Name'},
          { data: 'business_unit', title: 'Business Unit'},
          { data: 'assessment_due_date', title: 'Assessment date'},
          { data: 'dead_line', title: 'Due Date(Deadline)'},
          { data: 'finished_date', title: 'Assessor Finished Date'},
          { data: 'state', title: 'State'},
          { data: 'status', title: 'Status'},
          { data: 'trainee_name', title: 'Trainees Name'},
        ],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 10,
        loading: false,
        pdfLoading: false,
        excelLoading: false,
        baseUrl: process.env.VUE_APP_BASE_URL,
      };
    },
    computed() {
      return {};
    },
    methods: {
      checkBusinessUnit(emp_id) {
        return this.employees.find((emplist) => emplist.emp_id == emp_id)
          ?.business_unit?.name;
      },
      async getAllModules() {
        await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
          this.modules = response.data.data;
          this.$Progress.finish();
        });
      },
      async getAssessmentResult() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/ata-detail-report`,{
            params: {
                    page: this.currentPage,
                    per_page: this.pageSize,
                    assessor_id : this.employee, 
                    module_id : this.module,
                    status : this.status ? this.status -1 : ""
                }
        })
        .then((response) => {
          this.assessment_result = response.data.data.data;
          this.totalRecords = response.data.total;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
        async getWithoutPaginate(){
        
        this.loading = true;
        this.$Progress.start();
        await axios
            .get(`${this.baseUrl}admin/v2/ata-detail-report`,{
                params:  {
                    assessor_id : this.employee, 
                    module_id : this.module,
                    status : this.status
                } 
            })
            .then((res) => {
                this.exportData = res.data.data;
            })
            .catch((error) => {
                this.errors = error.response.data.errors;
                this.toast.error("Something Went Wrong!");
            }).finally(()=> {
                this.loading = false;
                this.$Progress.finish();
            });    

            
        },
      assessmentDate(date) {
        let assessmentDate = new Date(date);
        assessmentDate.setDate(assessmentDate.getDate() - 7);
        return assessmentDate.toISOString().split("T")[0];
      },
      finishedDate(created_at, updated_at) {
        if (created_at !== updated_at) {
          let date = new Date(updated_at);
          return date.toISOString().split("T")[0];
        }
        return "";
      },
      checkStatus(status) {
        if (status == 0) {
          return "Waiting";
        } else if (status == 1) {
          return "Confirmed";
        } else if (status == 2) {
          return "Expired";
        }
      },
      handlePagination({ currentPage, pageSize }) {
        if (this.ignoreNextEmit) {
          this.ignoreNextEmit = false;
          return;
        }
        if (this.pageSize !== pageSize) {
          this.currentPage = 1; // Reset to first page if page size changes
        }
  
        if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
          this.currentPage = currentPage;
          this.pageSize = pageSize;
          this.getAssessmentResult();
        }
      },
      async exportPdfClick() {
    
        await this.getWithoutPaginate();
    
        if (this.exportData.length == 0)
        return this.toast.error("There is no data to export!");
        this.pdfLoading = true;
        const sheetData = this.exportData.map((item, index) => {
                let row = [];
                row[0] = index + 1;
                row[1] = item.assessment_before_one_weeks?.monthly_plan?.module?.name;
                row[2] = item.assessment_before_one_weeks?.training_date;
                row[3] = item.assessment_before_one_weeks?.assessor.name;
                row[4] = this.checkBusinessUnit(item?.assessment_before_one_weeks?.assessor_id)
                row[5] = this.assessmentDate(item.assessment_before_one_weeks.assessment_due_date)
                row[6] = item.assessment_before_one_weeks.assessment_due_date;
                row[7] = this.finishedDate(item.created_at, item.updated_at) ;
                row[8] = item.state;
                row[9] = this.checkStatus(item.status);
                row[10] = item.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name;
            
            return row;
            });    
    
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    
      },
      async exportExcelClick() {
        await this.getWithoutPaginate();
        
        if (this.exportData.length == 0)
            return this.toast.error("There is no data to export!");
        this.excelLoading = true;
        
        const sheetData = [this.headers];
        
        this.exportData.forEach((item, index) => {
            sheetData.push([
                index + 1,
                item.assessment_before_one_weeks?.monthly_plan?.module?.name,
                item.assessment_before_one_weeks?.training_date,
                item.assessment_before_one_weeks?.assessor.name,
                this.checkBusinessUnit(item?.assessment_before_one_weeks?.assessor_id),
                this.assessmentDate(item.assessment_before_one_weeks.assessment_due_date),
                item.assessment_before_one_weeks.assessment_due_date,
                this.finishedDate(item.created_at, item.updated_at) ,
                item.state,
                this.checkStatus(item.status),
                item.assessment_before_one_weeks?.monthly_plan?.trainers[0]?.user?.name
            ]);
            });    
        exportExcel(sheetData, this.title);
        this.excelLoading = false;
      },
      async refreshData() {
        this.employee = "";
        this.module = "";
        this.status = "";
        this.currentPage = 1;
        this.pageSize = 10;
        await this.getAssessmentResult();
        },
    },
    watch: {
      module(module_id) {
          if(module_id){
                this.employee = "";
                this.status = "";
              this.getAssessmentResult();
          }
      },
      employee(emp_id) {
          if(emp_id) {
            this.status = "";    
              this.getAssessmentResult();
          }
      },
      status(value) {
            if(value) {
                this.getAssessmentResult();
            }   
      }
    },
    async created() {
      await this.getAssessmentResult();
      this.getAllModules(); 
  
    },
  };
  </script>
  
  <style>
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
  }
  </style>
  
<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Trainer Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <place-holder v-if="loading"></place-holder>
          <div v-show="!loading" class="card-body">
            <form class="parsley-examples" v-if="!loading">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="trainer" class="form-label">
                      Trainer Name<span class="text-danger">*</span>
                    </label>
                    <template v-if="currentRouteName == 'trainer-create'">
                      <v-select
                        @option:selected="getEmployee"
                        v-model="selectdEmployee"
                        id="trainer"
                        label="name"
                        :options="employees"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.trainer.name.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.trainer.name.$errors,
                          value: 'Trainer Name',
                        }"
                      ></v-errors>
                    </template>
                    <template v-else>
                      <input
                        name="name"
                        v-model="trainer.name"
                        class="form-control"
                        disabled
                      />
                    </template>
                  </div>
                  <div class="mb-3">
                    <label for="email" class="form-label"> Email </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="trainer.email"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label for="employee_number" class="form-label"
                      >Employee Number</label
                    >
                    <input
                      type="text"
                      id="employee_number"
                      name="employee_number"
                      class="form-control"
                      placeholder="Employee Number"
                      v-model="trainer.emp_id"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label for="phone" class="form-label">Phone</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      class="form-control"
                      placeholder="Phone Number"
                      v-model="trainer.phone"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label for="dob" class="form-label">Date Of Birth</label>
                    <input
                      type="text"
                      id="dob"
                      name="dob"
                      class="form-control"
                      placeholder="Date Of Birth"
                      v-model="trainer.birthday"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label for="gender" class="form-label mx-3">Gender</label>
                    <input
                      type="radio"
                      name="gender"
                      v-model="trainer.gender"
                      value="male"
                      style="cursor: not-allowed"
                      disabled
                    />
                    Male
                    <input
                      type="radio"
                      name="gender"
                      v-model="trainer.gender"
                      value="female"
                      style="cursor: not-allowed"
                      disabled
                    />
                    Female
                  </div>
                  <div class="mb-3">
                    <label for="image"
                      >Upload Image <span class="text-danger">*</span></label
                    >
                    <p class="fst-italic">
                      (The image size is limited to 1 MB, and the ratio is
                      360*360, which is the most convenient)
                    </p>
                    <section>
                      <div
                        width="40%"
                        class="upload dialog-center"
                        style="cursor: pointer"
                      >
                        <div class="uploadFile importFile mb-5">
                          <div class="upload-demo">
                            <input
                              ref="img-upload-input"
                              class="img-upload-input"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              @change="handleClick"
                            />
                            <div
                              class="drop"
                              @click="handleUpload"
                              @drop="handleDrop"
                              @dragover="handleDragover"
                              @dragenter="handleDragover"
                            >
                              <i class="el-icon-upload img-upload-icon"></i>
                              <br />
                              Drag and drop file <br />
                              or <br />
                              <div class="" style="font-size: larger">
                                <i class="fas fa-cloud-upload-alt"></i> Upload
                              </div>

                              <div class="preview" v-if="createImage">
                                <img :src="url" />
                              </div>

                              <div
                                v-else-if="
                                  trainer.image != null &&
                                  currentRouteName === 'trainer-update'
                                "
                                class="preview"
                              >
                                <img :src="imgUrl + trainer.image" />
                              </div>
                            </div>
                            <v-errors
                              :serverErrors="errorFor('image')"
                              :vuelidateErrors="{
                                errors: v$.trainer.image.$errors,
                                value: 'Image',
                              }"
                            ></v-errors>
                          </div>
                          <div
                            v-if="trainer.image"
                            @click="handleRemove"
                            id="remove"
                            class="icon-item"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-x icon-dual"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <!-- end col -->
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="position-level" class="form-label"
                      >Position Level</label
                    >
                    <input
                      type="text"
                      name="position_level"
                      placeholder="Position Level"
                      v-model="trainer.position_level"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="mb-3">
                    <label for="status" class="form-label">Status</label>
                    <select
                      name="status"
                      v-model="trainer.status"
                      class="form-control"
                      :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      "
                    >
                      <option :value="1">Active</option>
                      <option :value="0">Inactive</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label for="experience" class="form-label"
                      >Experience <span class="text-danger">*</span></label
                    >
                    <textarea
                      name="experience"
                      id="experience"
                      rows="8"
                      placeholder="Experience"
                      v-model="trainer.experience"
                      class="form-control"
                      :class="{
                        'p-invalid':
                          v$.trainer.experience.$error ||
                          errorFor('experience'),
                      }"
                    ></textarea>
                    <v-errors
                      :serverErrors="errorFor('experience')"
                      :vuelidateErrors="{
                        errors: v$.trainer.experience.$errors,
                        value: 'Experience',
                      }"
                    ></v-errors>
                  </div>

                  <div class="mb-3">
                    <label for="education" class="form-label"
                      >Education & Qualification
                      <span class="text-danger">*</span></label
                    >
                    <textarea
                      name="education"
                      id="education"
                      rows="8"
                      placeholder="Education & Qualification"
                      v-model="trainer.education"
                      class="form-control"
                      :class="{
                        'p-invalid':
                          v$.trainer.education.$error || errorFor('education'),
                      }"
                    ></textarea>
                    <v-errors
                      :serverErrors="errorFor('education')"
                      :vuelidateErrors="{
                        errors: v$.trainer.education.$errors,
                        value: 'Education',
                      }"
                    ></v-errors>
                  </div>
                  <!-- end col -->
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainers' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'trainer-update'
                          ? updateTrainer()
                          : createTrainer()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      trainer: {
        user_id: "",
        name: "",
        emp_id: "",
        email: "",
        image: "",
        status: "1",
        education: "",
        experience: "",
        model_name: "trainer",
      },
      selectdEmployee: "",
      filterEmployee: [],
      employees: this.$store.getters["odoo/getAllActiveEmployees"],
      baseUrl: process.env.VUE_APP_BASE_URL,
      imgUrl: process.env.VUE_APP_BASE_URL.replace('/api/', ''),
       
      url: null,
      createImage: false,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      trainer: {
        id: "",
        name: { required },
        image: { required },
        education: { required },
        experience: { required },
      },
    };
  },
  methods: {
    // async changeFile(e){
    //    console.log(e.target);
    //    if(e.file) {
    //       this.trainer.image = e.file
    //    }
    // },
    handleUpload() {
      this.$refs["img-upload-input"].click();
    },

    handleClick(e) {
      this.trainer.image = null;
      this.url = null;
      const file = e.target.files[0];
      this.trainer.image = file;
      this.url = URL.createObjectURL(file);
      this.createImage = true;
    },

    handleDragover() {
      this.$refs["img-upload-input"].click();
    },
    handleRemove() {
      this.trainer.image = null;
      this.url = null;
    },
    async getEmployees() {
      this.employees = this.$store.getters["odoo/getAllActiveEmployees"];
      if (this.employees.length == 0) {
        this.loading = true;
        setTimeout(() => {
          this.getEmployees();
        }, 1000);
      } else {
        this.loading = false;
      }
    },
    async getEmployee() {
      this.loading = true;
      if (this.selectdEmployee) {
        this.trainer.user_id = this.selectdEmployee.id;
        this.trainer.email = this.selectdEmployee.email;
        this.trainer.name = this.selectdEmployee.name;
        this.trainer.emp_id = this.selectdEmployee.emp_id;
        this.trainer.status = "1";

        this.trainer.birthday = moment(this.selectdEmployee.birthday).format(
          "DD-MM-YYYY"
        );
        this.trainer.phone = this.selectdEmployee.phone_no;
        this.trainer.gender = this.selectdEmployee.gender;
        this.trainer.position_level = this.selectdEmployee.position_level.name;
      }
      this.loading = false;
    },

    async createTrainer() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let form = new FormData();
      form.append("user_id", this.trainer.user_id);
      form.append("trainer_profile", this.trainer.image);
      form.append("name", this.trainer.name);
      form.append("email", this.trainer.email);
      if (this.trainer.emp_id == false || this.trainer.emp_id == null) {
        this.toast.error("Employee Id Is Null. This Trainer Cannot Create!");
        return;
      } else {
        form.append("emp_id", this.trainer.emp_id);
      }
      form.append("status", this.trainer.status);
      form.append("education", this.trainer.education);
      form.append("experience", this.trainer.experience);
      form.append("model_name", this.trainer.model_name);

      this.isLoading = true;

      axios
        .post(`${this.baseUrl}admin/v1/trainers`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$router.push({ name: "trainers" });
          this.toast.success("Successfully Trainer Created!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          if (this.errors.trainer_profile) {
            this.toast.error(this.errors.trainer_profile[0]);
          } else if(this.errors.id){
            this.toast.error(this.errors.id[0]);
          }
           else {
            this.toast.error("Something Went Wrong!");
          }
          this.isLoading = false;
        });
    },
    async updateTrainer() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let updateForm = new FormData();
      updateForm.append("trainer_profile", this.trainer.image);
      updateForm.append("education", this.trainer.education);
      updateForm.append("experience", this.trainer.experience);
      updateForm.append("model_name", this.trainer.model_name);
      updateForm.append("status", this.trainer.status);
      updateForm.append("_method", "put");

      this.isLoading = true;
      axios
        .post(
          `${this.baseUrl}admin/v1/trainers/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "trainers" });
          this.toast.success("Successfully Trainer Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          if (this.errors.trainer_profile) {
            this.toast.error(this.errors.trainer_profile[0]);
          } else {
            this.toast.error("Something Went Wrong!");
          }
          this.isLoading = false;
        });
    },
    async getTrainerById() {
      this.loading = true;
      axios
        .get(`${this.baseUrl}admin/v1/trainers/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;
          this.trainer.name = result.name;
          this.trainer.emp_id = result.employee_id;
          this.trainer.user_id = result.id;
          this.trainer.email = result.email;
          this.trainer.education = result.education;
          this.trainer.experience = result.experience;
          this.trainer.image = result.image_url;
          this.trainer.status = result.status;
          this.trainer.phone = result.phone;
          this.trainer.birthday = result.birthday;
          this.trainer.gender = result.gender;
          this.trainer.position_level = result.position_name;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err, "error ");
          this.toast.error("Not Found Trainer!");
        });
    },
  },
  created() {
    if (this.currentRouteName == "trainer-update") {
      this.getTrainerById();
    } else {
      this.getEmployees();
    }
    // console.log(this.$store.getters["odoo/getLoading"], "loading");
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}

.img-upload-input {
  display: none;
  z-index: -9999;
}

.img-upload-icon {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.drop,
.preview {
  min-height: 220px;
  border: 2px dashed #d9d9d9;
  width: 100%;
  height: auto;
  margin: auto;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  /* background-image: linear-gradient(#71d5e3, #dcebe5) !important; */
}

.preview {
  position: absolute;
  top: 0;
  border: unset;
}

.preview img {
  max-width: 100%;
  max-height: 215px;
}

.uploadFile {
  position: relative;
  width: 100%;
  height: 100%;
}

#remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer !important;
  z-index: 5;
  text-align: center !important;
  font-size: 1em;
  width: 1.8em;
  height: 1.8em;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  box-shadow: 0 0 0 0 hsl(0deg 0% 100% / 0%);
  transition: box-shadow 0.25s ease-in;
}

#remove > .icon-dual {
  color: #fff !important;
  font-size: 1em;
}
</style>
